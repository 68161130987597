import React, { useContext, useMemo, useCallback, useState, useRef, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import { graphql } from 'gatsby'
import {
  Layout,
  SEO,
  HeroHeading,
  Section,
  Heading,
  Box,
  Button,
  Paragraph,
  Loader,
  Modal,
  ModalHeader,
  ModalClose,
  ModalContent,
  Iframe,
  Icon,
} from '@components'
import { Page, ACF } from '@types'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Ul,
  Li,
  A,
} from '@bootstrap-styled/v4'
import { useLabels, useSiteMetadata, useForm } from '@hooks'
import { formatPhoneNumber, simpleFetch, scrollToElement, isClient } from '@utils'
import Recaptcha from 'react-recaptcha'
import MiniLazyload from 'minilazyload'

type ContactACF = ACF<{
  email: string
  phonenumber: string
  address: string
  officehours: string
  vatid: string
  taxid: string
}>

type FormValues = {
  fullName: string
  phoneNumber: string
  email: string
  message: string
}

enum FormStatus {
  ERROR = 'validation_failed',
  SUCCESS = 'mail_sent',
}

const initialValues: FormValues = {
  fullName: '',
  phoneNumber: '',
  email: '',
  message: '',
}

let recaptchaInstance: Recaptcha | null

const ContactPage: React.FC<Page<ContactACF>> = ({ data, location }) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState(false)
  const [formSent, setFormSent] = useState(false)
  const formWrapperRef = useRef<HTMLDivElement>(null)
  const [recaptchaToken, setRecaptchaToken] = useState('')

  const { title, content, acf } = data.wpgraphql.page
  const { transitionDurations, colors } = useContext(ThemeContext)
  const labels = useLabels()
  const site = useSiteMetadata()

  const asterisk = useMemo(() => <span aria-labelledby="mandatoryField">*</span>, [])

  const scrollToFormError = useCallback(() => {
    formWrapperRef.current && scrollToElement(formWrapperRef.current)
  }, [])

  const handleSubmit = useCallback(
    (values: FormValues) => {
      // clear form error
      setFormError(false)

      // check if recaptcha passed
      if (!recaptchaToken) {
        setFormError(true)
        scrollToFormError()
        return
      }

      // clear states
      setSubmitting(true)
      setFormSent(false)

      const formData = new FormData()
      Object.keys(values).forEach(key => {
        formData.append(key, values[key as keyof FormValues])
      })

      simpleFetch
        .post(site.contactFormEndpoint, formData)
        .then(data => {
          if (data.status === FormStatus.ERROR) {
            // display error message
            setFormError(true)
            scrollToFormError()
          } else if (data.status === FormStatus.SUCCESS) {
            // display thank you message
            setFormSent(true)

            // clear form data
            setValues(initialValues)

            // clear recaptcha token
            setRecaptchaToken('')

            // clear recaptcha instance
            recaptchaInstance && recaptchaInstance.reset()
          }
        })
        .catch(() => {
          setFormError(true)
          scrollToFormError()
        })
        .finally(() => setSubmitting(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recaptchaToken, scrollToFormError, site.contactFormEndpoint],
  )

  const { values, setValues, handleInputChange, handleFormSubmit } = useForm<FormValues>(
    initialValues,
    handleSubmit,
  )

  useEffect(() => {
    if (isClient) {
      new MiniLazyload(
        {
          rootMargin: '200px',
        },
        '.js-lazyload',
        true,
      )
    }
  }, [])

  return (
    <Layout>
      <SEO title={title} pageUrl={location.pathname} />
      <Section hasBg verticalPadding>
        <Container>
          <div className={`${!content ? 'pb-3' : ''}`}>
            <HeroHeading
              className="mb-5"
              data-sal="slide-up"
              data-sal-duration={transitionDurations.sal}
            >
              {title}
            </HeroHeading>
          </div>
          {content && (
            <div
              className="mb-5"
              dangerouslySetInnerHTML={{ __html: content }}
              data-sal="slide-up"
              data-sal-duration={transitionDurations.sal}
            />
          )}
          <Row>
            <Col xs="12" xl="8">
              <Row>
                <Col
                  xs="12"
                  sm="6"
                  lg="4"
                  xl="6"
                  className="mb-4"
                  data-sal="slide-up"
                  data-sal-duration={transitionDurations.sal}
                >
                  <Heading variant="h2" textSize="xxlarge" className="mb-4">
                    {labels.contact}
                  </Heading>
                  <Paragraph>
                    <Paragraph as="strong" textSize="xlarge">
                      {site.title}
                    </Paragraph>
                    <br />
                    {site.owner}
                  </Paragraph>
                  <div dangerouslySetInnerHTML={{ __html: acf?.address ?? '' }} />
                  <Paragraph>
                    {labels.email} <a href={`mailto:${acf?.email}`}>{acf?.email}</a>
                    <br />
                    {acf?.phonenumber && (
                      <>
                        {labels.phoneNumber}{' '}
                        <a href={`tel:${acf?.phonenumber}`}>{formatPhoneNumber(acf.phonenumber)}</a>
                      </>
                    )}
                  </Paragraph>
                  {(acf?.taxid || acf?.vatid) && (
                    <Paragraph>
                      {acf.taxid && (
                        <>
                          {labels.taxId} {acf.taxid}
                          <br />
                        </>
                      )}
                      {acf.vatid && (
                        <>
                          {labels.vatId} {acf.vatid}
                        </>
                      )}
                    </Paragraph>
                  )}
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  lg="4"
                  xl="6"
                  className="mb-4"
                  data-sal="slide-up"
                  data-sal-duration={transitionDurations.sal}
                >
                  <Heading variant="h2" textSize="xxlarge" className="mb-4">
                    {labels.officeHours}
                  </Heading>
                  {acf?.officehours && (
                    <div className="mb-3" dangerouslySetInnerHTML={{ __html: acf.officehours }} />
                  )}
                  <Ul className="list-inline list-unstyled mb-0" role="list">
                    <Li className="list-inline-item mr-2">
                      <A
                        href={site.instagramUrl}
                        aria-label="Instagram"
                        theme={{ '$link-hover-color': colors.primaryHover }}
                      >
                        <Icon name="Instagram" width={34} height={34} color={colors.primary} />
                      </A>
                    </Li>
                    <Li className="list-inline-item mr-2">
                      <A
                        href={site.facebookUrl}
                        aria-label="Facebook"
                        theme={{ '$link-hover-color': colors.primaryHover }}
                      >
                        <Icon name="Facebook" width={34} height={34} color={colors.primary} />
                      </A>
                    </Li>
                  </Ul>
                </Col>
                <Col
                  xs="12"
                  className="mb-5"
                  data-sal="slide-up"
                  data-sal-duration={transitionDurations.sal}
                >
                  <Iframe
                    data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2527.7859451298796!2d14.535945216154493!3d50.68679817950871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47096955352b14c7%3A0x152a3135c700ffc1!2zTWFyacOhbnNrw6EgMjE2LCA0NzAgMDEgxIxlc2vDoSBMw61wYQ!5e0!3m2!1scs!2scz!4v1592754363522!5m2!1scs!2scz"
                    style={{ width: '100%' }}
                    height="325"
                    title={labels.map}
                    frameBorder="0"
                    className="js-lazyload"
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xs="12"
              xl="4"
              className="mb-5"
              data-sal="slide-up"
              data-sal-duration={transitionDurations.sal}
            >
              <Heading variant="h2" textSize="xxlarge" className="mb-4">
                {labels.contactFormHeading}
              </Heading>
              <Box ref={formWrapperRef} smallPadding>
                <Loader isVisible={isSubmitting} />
                {formError && (
                  <Alert color="danger">
                    {!recaptchaToken ? labels.recaptchaRequired : labels.formErrorMessage}
                  </Alert>
                )}
                <Form onSubmit={handleFormSubmit}>
                  <FormGroup>
                    <Label htmlFor="fullName">
                      {labels.fullName} {asterisk}
                    </Label>
                    <Input
                      type="text"
                      id="fullName"
                      name="fullName"
                      value={values.fullName}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="phoneNumber">
                      {labels.phoneNumber} {asterisk}
                    </Label>
                    <Input
                      type="tel"
                      pattern="^(\+42(0|1))??[0-9]{3}?[0-9]{3}?[0-9]{3}$"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">{labels.email}</Label>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="message">
                      {labels.message} {asterisk}
                    </Label>
                    <Input
                      type="textarea"
                      id="message"
                      name="message"
                      value={values.message}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup className="overflow-hidden">
                    {isClient && (
                      <Recaptcha
                        sitekey={site.recaptchaSitekey}
                        render="explicit"
                        verifyCallback={token => {
                          setRecaptchaToken(token)
                          setFormError(false)
                        }}
                        expiredCallback={() => setRecaptchaToken('')}
                        ref={e => (recaptchaInstance = e)}
                        hl="cs"
                      />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Paragraph
                      as="small"
                      textSize="xsmall"
                      dangerouslySetInnerHTML={{ __html: labels.mandatoryNote }}
                    />
                  </FormGroup>
                  <div className="text-center">
                    <Button type="submit" color="secondary" disabled={isSubmitting}>
                      {labels.submit}
                    </Button>
                  </div>
                </Form>
              </Box>
            </Col>
          </Row>
          <div data-sal="slide-up" data-sal-duration={transitionDurations.sal}>
            <Heading variant="h2" textSize="xxlarge" className="mb-4">
              {labels.questionnaire}
            </Heading>
            <Paragraph>{labels.questionnaireDescription}</Paragraph>
            <Row>
              <Col xs={12} sm={6} md={5} lg={4} xl={3} className="mb-3">
                <a
                  href="/anamnesticky-dotaznik-dospely.pdf?v=2"
                  className="d-inline-flex align-items-center"
                >
                  <Icon name="Adult" size={34} color={colors.primary} className="mr-2" />
                  <span>{labels.questionnaireAdult}</span>
                </a>
              </Col>
              <Col xs={12} sm={6} md={5} lg={4} xl={3} className="mb-3">
                <a
                  href="/anamnesticky-dotaznik-dite.pdf?v=2"
                  className="d-inline-flex align-items-center"
                >
                  <Icon name="Child" size={34} color={colors.primary} className="mr-2" />
                  <span>{labels.questionnaireChild}</span>
                </a>
              </Col>
            </Row>
          </div>
        </Container>
      </Section>

      {/* Form sent modal */}
      <Modal
        isOpen={formSent}
        labelledby="formSentModalTitle"
        onRequestClose={() => setFormSent(false)}
        shouldCloseOnOverlayClick={false}
      >
        <ModalHeader id="formSentModalTitle" title={labels.formSentTitle}>
          <ModalClose onClick={() => setFormSent(false)} />
        </ModalHeader>
        <ModalContent>
          <Alert color="success" style={{ marginBottom: 0 }}>
            <Paragraph style={{ marginBottom: 0 }}>{labels.formSentMessage}</Paragraph>
          </Alert>
        </ModalContent>
      </Modal>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    wpgraphql {
      page(id: "19", idType: DATABASE_ID) {
        slug
        title(format: RENDERED)
        uri
        databaseId
        isFrontPage
        content(format: RENDERED)
        acf {
          email
          phonenumber
          address
          officehours
          taxid
          vatid
        }
      }
    }
  }
`
